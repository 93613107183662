import gql from 'graphql-tag';

export const ORDER_SEARCH_QUERY = gql`
  query GetOrdersByFilter($filter: OrderFilterInput, $page: Int, $size: Int) {
    order {
      orders(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            customer {
              id
              nickname
              gender
              email
              mobileNumber
            }
            status
            netAmount {
              value
              currencyCode
            }
            totalAmount {
              value
              currencyCode
            }
            transactions {
              id
              firmedAt
              type
              status
            }
            items {
              id
              info {
                id
                name
                product {
                  id
                  title
                }
              }
              count
              remains
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

export const TRANSACTION_LIST_QUERY = gql`
  query GetTransactionsByFilter(
    $filter: TransactionFilterInput
    $page: Int
    $size: Int
  ) {
    order {
      transactions(filter: $filter, page: $page, size: $size) {
        totalCount
        edges {
          node {
            id
            order {
              id
              customer {
                id
                nickname
                gender
                email
                mobileNumber
              }
            }
            type
            status
            firm
            firmedAt
            actionStatus
            netAmount {
              value
              currencyCode
            }
            totalAmount {
              value
              currencyCode
            }
            items {
              id
              info {
                id
                name
                product {
                  id
                  title
                }
              }
              count
              remains
            }
            payments {
              id
              methodLabel
              amount {
                value
                currencyCode
              }
            }
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

export const ORDER_DETAIL_QUERY = gql`
  query GetOrderDetail($id: ID!) {
    order {
      order(id: $id) {
        id
        status
        usedCouponCount
        customer {
          id
          nickname
          gender
          email
          mobileNumber
        }
        transactions {
          id
          type
          firm
          firmedAt
          status
          cancelReasonKind
          worker {
            id
          }
          cancelReason
          items {
            id
            origin {
              id
              count
              remains
              units(includeCancel: true) {
                id
                indexNo
                status
                replies {
                  id
                  title
                  answer
                  selections {
                    label
                    value
                  }
                }
              }
            }
            info {
              id
              name
              type
              product {
                id
                title
              }
              options {
                id
                name
              }
              price {
                sale
                retail
                discountRate
                currencyCode
              }
            }
            count
            remains
            units(includeCancel: true) {
              id
              indexNo
              status
              replies {
                id
                title
                answer
                selections {
                  label
                  value
                }
              }
            }
            booking {
              id
              expiredAt
              schedule {
                id
                term {
                  startedAt
                  endedAt
                }
              }
            }
          }
          payments {
            id
            agencyType
            method
            methodLabel
            amount {
              value
              currencyCode
            }
            status
            result {
              code
              message
            }
          }
        }
      }
    }
  }
`;

export const ESTIMATE_CANCEL_QUERY = gql`
  query EstimateCancel($param: CancelRequestParamInput, $tid: String) {
    order {
      estimateCancel(param: $param, tid: $tid) {
        success
        message
        infos {
          amount {
            value
            currencyCode
          }
          label
          type
        }
        payments {
          agencyType
          amount {
            value
            currencyCode
          }
          method
          methodLabel
        }
        refunds {
          criterion
          text
          daysFrom
          daysTo
          days
          refundFee {
            value
            currencyCode
          }
          noRefund
        }
      }
    }
  }
`;

export const REFUND_BANKS_QUERY = gql`
  query GetRefundBanks {
    payment {
      refundBankList {
        code
        name
      }
    }
  }
`;

export const COUPON_PAYMENT_QUERY = gql`
  query GetCouponPayment($pid: ID!) {
    payment {
      coupon(id: $pid) {
        id
        issuedCoupon {
          id
          coupon {
            id
            title
            description
          }
        }
      }
    }
  }
`;

/**
 * settlement domain의 쿼리이지만, 실질적으로 order domain에서 사용하기 때문에 order domain 폴더에 추가.
 */
export const CHECK_EXISTENCE_OF_INVOICE_BY_ORDER_ITEM_UNIT_INFO = gql`
  query checkExistenceOfInvoiceByOrderItemUnitInfo(
    $orderItemId: String!
    $indexNo: Int!
  ) {
    settlement {
      checkExistenceOfInvoiceByOrderItemUnitInfo(
        orderItemId: $orderItemId
        indexNo: $indexNo
      )
    }
  }
`;

import gql from 'graphql-tag';

export const VERIFY_BANK_ACCOUNT_MUTATION = gql`
  mutation VerifyBankAccount($param: BankAccountVerifyParamInput!) {
    verifyBankAccount(param: $param) {
      success
      message
    }
  }
`;

export const CANCEL_ORDER_MUTATION = gql`
  mutation CancelOrder($param: CancelRequestParamInput!) {
    cancelOrder(param: $param) {
      success
      message
      requiredRefund
    }
  }
`;

export const PROCESS_CANCEL_ORDER_MUTATION = gql`
  mutation ProcessCancelOrder($tid: String!, $param: CancelRequestParamInput!) {
    processCancelOrder(tid: $tid, param: $param, payments: []) {
      success
      message
      requiredRefund
    }
  }
`;

export const GENERATE_TRANSACTION_FILE = gql`
  mutation GenerateTransactionFile($filter: TransactionFilterInput!) {
    generateTransactionsFile(filter: $filter) {
      success
      message
      url
    }
  }
`;






import Vue from 'vue';

interface Money {
  value: string;
  currenyCode: string; // TODO: 나중에 통화에 따라 금액 표시를 다르게 하도록 설정한다.
}

export default Vue.extend({
  name: 'MoneyLabel',
  props: {
    amount: {
      type: Object,
    },
  },
  computed: {
    money(): Money {
      return this.amount as Money;
    },
    amountValue(): number {
      return Number(this.money?.value || 0);
    },
    amountLabel(): string {
      return this.amountValue.toLocaleString();
    },
    textStyle(): string {
      return this.amountValue >= 0 ? 'frip-primary' : 'frip-danger';
    },
  },
});
















































import Vue from 'vue';
import { REFUND_BANKS_QUERY } from '../graphql/query';
import { VERIFY_BANK_ACCOUNT_MUTATION } from '../graphql/mutation';
import { RefundAccount } from '../model/RefundAccount';

export default Vue.extend({
  name: 'BankAccountInput',
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      banks: [],
    };
  },
  computed: {
    refundBank: function (): RefundAccount {
      return this.value as RefundAccount;
    },
  },
  methods: {
    onChangeData(val: any): void {
      console.log('onChangeData:', val);

      if (this.refundBank.verified) {
        this.refundBank.verified = false;
      }
      this.$emit('input', this.refundBank);
    },
    async verify() {
      const { data } = await this.$apollo.mutate({
        mutation: VERIFY_BANK_ACCOUNT_MUTATION,
        variables: {
          param: {
            code: this.refundBank.code,
            accountNo: this.refundBank.accountNo,
            holder: this.refundBank.holder,
          },
        },
      });
      this.refundBank.verified = data.verifyBankAccount.success;
      this.$emit('input', this.refundBank);

      if (!data.verifyBankAccount.success) {
        const message = data.verifyBankAccount.message;
        this.$modal.show({
          title: '계좌 검증 오류',
          message: message,
          type: 'warning',
        });
      }
    },
  },
  apollo: {
    banks: {
      query: REFUND_BANKS_QUERY,
      update: data => {
        const list = data.payment.refundBankList as {
          code: string;
          name: string;
        }[];
        const banks = list.map(i => {
          return {
            value: i.code,
            text: i.name,
          };
        });
        banks.unshift({ value: '', text: '은행을 선택하세요.' });
        return banks;
      },
    },
  },
});

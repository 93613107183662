export const toStringArray = (
  items?: string | string[]
): string[] | undefined => {
  if (items) {
    if (Array.isArray(items)) {
      return items as string[];
    }
    return [items];
  }
};
